function NavbarController($document, $rootScope, $scope, $log,
$location, $timeout, $route) {
  // TOOD: move to directive
  var self = this;
  var navbarToggle;
  var menu;
  var linkParents;

  this.$onInit = function() {
    navbarToggle = $document[0].querySelector('#navbar-toggle-parent');
    menu = $document[0].querySelector('#navbar-collapse-parent');

    //initialize
    linkParents = _getLinkParents();
    self.markMenu(linkParents, $location);

    $rootScope.$on('$locationChangeSuccess', function($event, newUrl, oldUrl) {
      // Way of updating url to clear state for components that update the url with
      // get variables, which requires setting reloadOnSearch: false
      // so in order to get traditional link-like behavior, need to manually reload
      if(oldUrl === newUrl || (oldUrl.indexOf('?') > -1 && newUrl.indexOf('?') === -1 && oldUrl.indexOf(newUrl) > -1)) {
        $route.reload();
        $log.debug('route reloaded in locationChangeSuccess');
      }
    });

    $rootScope.$on('$routeChangeSuccess', function($event, newUrl, oldUrl) {
      linkParents = _getLinkParents();
      self.markMenu(linkParents, $location);

      $log.debug('route changed');
    });

    var navCollapsed = true;
    $document.on('click', function(event) {
      if (navCollapsed && navbarToggle === event.target) {
        menu.classList.remove('collapse');
        navbarToggle.classList.add('active');
        navCollapsed = false;
        return;
      }

      if (!navCollapsed) {
        menu.classList.add('collapse');
        navbarToggle.classList.remove('active');
        navCollapsed = true;
      }
    });
  };
  
  function _getLinkParents() {
    return $document[0].querySelectorAll('[data-active]');
  }
}

NavbarController.prototype.markMenu = function(elems, $location) {
  var locationPath = $location.path();
  for ( var i = 0; i < elems.length; i++) {
    if (elems[i].getAttribute('data-active') === locationPath ) {
      if (!elems[i].classList.contains('active') ) {
        elems[i].classList.add('active');
      }
      
    // the className default check still changes DOM
    } else if (elems[i].classList.contains('active') ) {
      elems[i].classList.remove('active');
    }
  }
};

angular.module('sq.navbar.component', ['sq.navbar.links.component','sq.navbar.profile.component'])
.component('navbar', {
  templateUrl: 'navbar/navbar.tpl.html',
  transclue: true,
  controller: NavbarController,
});
